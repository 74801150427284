<template>
  <div class="p-p-lg-3">
    <i class="pi pi-circle-on orange p-d-inline"></i>
    <div class="hf1 orange p-d-inline p-ml-3">Result By {{ hari }}</div>

    <div class="card2 p-mt-3 p-px-3 p-shadow-4" style="color: white">
      <div class="p-grid p-pt-2 p-mb-0 p-pb-0">
        <div
          class="p-col-fixed p-text-center box p-text-bold p-p-2 not-round"
          style="width: 50px"
        >
          No
        </div>
        <div
          class="p-col p-d-none p-d-md-inline p-text-center box p-text-bold p-p-2 not-round"
        >
          Day
        </div>
        <div class="p-col p-text-center box p-text-bold p-p-2 not-round">
          Date
        </div>
        <div
          class="p-col-fixed p-text-center box p-text-bold p-p-2 not-round"
          style="width: 50px"
        >
          Draw
        </div>
        <div class="p-col p-text-center box p-text-bold p-p-2 not-round">
          1st Prize
        </div>
        <div class="p-col p-text-center box p-text-bold p-p-2 not-round">
          2nd Prize
        </div>
        <div class="p-col p-text-center box p-text-bold p-p-2 not-round">
          3rd Prize
        </div>
      </div>
      <!--table content-->
      <div class="p-mt-0 p-pb-2">
        <div
          class="p-grid p-mt-0 p-text-center menu-item"
          v-for="(data, index) in filtered_data"
          :key="index"
        >
          <div class="p-col-fixed bordered" style="width: 50px">
            {{ index + 1 }}
          </div>
          <div class="p-col p-d-none p-d-md-inline bordered">
            {{ data.draw_date_name }}
          </div>
          <div class="p-col bordered">{{ data.draw_date }}</div>
          <div class="p-col-fixed bordered" style="width: 50px">
            {{ data.draw_no }}
          </div>
          <div class="p-col bordered">{{ data.draw_prize1 }}</div>
          <div class="p-col bordered">{{ data.draw_prize2 }}</div>
          <div class="p-col bordered">{{ data.draw_prize3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { hari: String },
  data() {
    return {
      filtered_data: null,
      draw_data: null,
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      acak: this.$store.getters.getAcak,
      waktu:this.$store.state.waktu,
    };
  },
  methods: {
    async getResult() {
      await this.$http.get("/result").then((r) => {
        this.draw_data = r.data;
        this.filterHari();
      });
    },
    getDay(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
    filterHari() {
      if (this.draw_data != null) {
        this.filtered_data = [];
        var q = this.draw_data.filter((i) => {
          if (i.draw_date_name == this.hari) {
            return i;
          }
        });
        if (!this.cekDateBigger(new Date(), new Date(q[0].draw_date))) {
         q.shift();
        } 
        this.filtered_data = q;
      }
    },
    setResult(data) {
      this.draw_no = data.draw_no;
      this.draw_date = data.draw_date;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
    cekDateBigger(nowDate, drawDate) {
      var a = nowDate.getDate() + nowDate.getMonth() + nowDate.getFullYear();
      var b = drawDate.getDate() + drawDate.getMonth() + drawDate.getFullYear();
      if (a > b) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    hari: {
      handler: "filterHari",
      immediate: true,
    },
  },
};
</script>
